import React, { FC } from 'react';
import IconCustom from 'components/common/IconCustom';
import GatsbyImage from 'components/common/GatsbyImage';
import DangerouslySetInnerHtml from 'gatsby-theme-gaviscon/src/components/common/DangerouslySetInnerHtml';
import { DiscoveryBlockProps } from './model';
import './DiscoveryBlock.scss';

const DiscoveryBlock: FC<DiscoveryBlockProps> = ({ description, title, image, imageAlt }) => (
  <div className="gs-discovery-block" data-testid="discovery-block">
    {image?.fallbackUrl ? (
      <GatsbyImage className="gs-discovery-block__image" fluid={image} alt={imageAlt} />
    ) : null}
    <strong className="gs-discovery-block__title">
      <IconCustom icon="tick" />
      <DangerouslySetInnerHtml html={title} />
    </strong>
    <DangerouslySetInnerHtml className="gs-discovery-block__description" html={description} />
  </div>
);

export default DiscoveryBlock;
