import React, { FC } from 'react';
import { graphql } from 'gatsby';
import { Element } from 'react-scroll';
import Container from 'react-bootstrap/Container';
import classnames from 'classnames';

import ConditionalWrapper from 'components/common/ConditionalWrapper/CondidionalWrapper';
import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';
import DiscoveryBlock from 'components/DiscoveryBlock';
import BrandHistory from 'components/BrandHistory';
import GatsbyImage from 'components/common/GatsbyImage';

import { DiscoverySectionProps } from './model';

import './DiscoveryTextSection.scss';

const DiscoveryTextSection: FC<DiscoverySectionProps> = ({
  title,
  description,
  image,
  imageAlt,
  showInMenu,
  color,
  blocks,
  history,
  imagePosition,
  anchorName,
}) => (
  <ConditionalWrapper
    condition={showInMenu === '1'}
    wrapper={(children) => (
      <Element name={anchorName || title} className="element">
        {children}
      </Element>
    )}
  >
    <div
      className={classnames('gs-discovery-section', {
        [`gs-discovery-section--bg-${color}`]: color,
        [`gs-discovery-section--image-${imagePosition[0]}`]: imagePosition.length,
        'gs-discovery-section--history': history?.length,
      })}
    >
      <div className="gs-discovery-section__crop">
        <Container fluid>
          <div className="gs-discovery-section__intro">
            {title || description ? (
              <div className="gs-discovery-section__intro-text">
                <DangerouslySetInnerHtml
                  element="strong"
                  className="gs-discovery-section__intro-title"
                  html={title}
                />
                <DangerouslySetInnerHtml
                  className="gs-discovery-section__intro-description"
                  html={description}
                />
              </div>
            ) : null}
            {history?.length ? (
              <BrandHistory history={history} />
            ) : image?.fallbackUrl ? (
              <div className="gs-discovery-section__intro-image">
                <GatsbyImage fluid={image} alt={imageAlt} />
              </div>
            ) : null}
          </div>
          {blocks?.length ? (
            <div
              className={classnames('gs-discovery-section__blocks', {
                'gs-discovery-section__blocks--offset': blocks?.length > 2,
              })}
            >
              {blocks.map(({ properties: blockProperties }) => (
                <DiscoveryBlock key={blockProperties.title} {...blockProperties} />
              ))}
            </div>
          ) : null}
        </Container>
      </div>
    </div>
  </ConditionalWrapper>
);

export const query = graphql`
  fragment FragmentDiscoverySectionStructure on TDiscoverySectionStructure {
    structure
    properties {
      blocks {
        properties {
          description
          image {
            ...FragmentGatsbyImage
          }
          imageAlt
          title
        }
      }
      color
      description
      image {
        ...FragmentGatsbyImage
      }
      imageAlt
      showInMenu
      title
      anchorName
      imagePosition
    }
  }
`;

export default DiscoveryTextSection;
