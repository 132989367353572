import React, { FC, useState, useEffect } from 'react';

import ControlledCarousel from 'components/ControlledCarousel';
import useReducedMotion from 'hooks/useReducedMotion';
import homeBannerTypes from '../homeBannerTypes';

import { IHomeBannerSliderProps } from './model';
import responsiveObj from './constants';
import './HomeBannerSlider.scss';

const HomeBannerSlider: FC<IHomeBannerSliderProps> = ({ topBanner }) => {
  const isAnimationsReduced = useReducedMotion();
  const [componentTypes, setComponentTypes] = useState<string[]>([]);

  useEffect(() => {
    const types = topBanner.map((banner) => banner.properties.bannerType?.[0] || 'default');
    setComponentTypes(types);
  }, [topBanner]);

  return (
    <ControlledCarousel
      showDots
      swipeable
      infinite
      autoPlay={!isAnimationsReduced}
      autoPlaySpeed={5000}
      classes="home-banner__carousel"
      responsiveObj={responsiveObj}
    >
      {topBanner.map(({ properties }, idx) => {
        const ComponentToRender = homeBannerTypes[componentTypes[idx]] || homeBannerTypes.default;
        return (
          <ComponentToRender
            key={properties.description}
            bannerContent={properties}
            showStickyLink={false}
            isMainBanner={idx === 0}
          />
        );
      })}
    </ControlledCarousel>
  );
};

export default HomeBannerSlider;
