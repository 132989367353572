import React, { FC } from 'react';

import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';
import Button from 'components/common/Button';
import IconCustom from 'components/common/IconCustom';
import GatsbyImage from 'components/common/GatsbyImage';

import { BannerSymptomsContentProps } from './model';
import './BannerSymptomsContent.scss';

const BannerSymptomsContent: FC<BannerSymptomsContentProps> = ({
  bannerContent: {
    title,
    description,
    image,
    imageAlt,
    productImage,
    productImageAlt,
    link: cta,
    linkVariant,
    veevaNumber,
  },
  isMainBanner = true,
}) => {
  const link = cta?.length ? cta[0] : null;
  const ctaPath = (link?.url || '').concat(link?.queryString || '');
  const variant = linkVariant?.[0] || 'primary';

  const HeadingTag = isMainBanner ? 'h1' : 'h2';

  return (
    <div className="home-banner-symptoms" data-testid="home-banner">
      <div className="home-banner-symptoms__content">
        <div className="home-banner-symptoms__left">
          <div className="home-banner-symptoms__left-image">
            <GatsbyImage
              isLazyLoading={isMainBanner}
              objectPosition="100% 100%"
              fluid={productImage}
              alt={productImageAlt}
            />
          </div>
        </div>
        <div className="home-banner-symptoms__right">
          <div className="home-banner-symptoms__right-image">
            {image?.fallbackUrl ? (
              <GatsbyImage
                isLazyLoading={isMainBanner}
                fluid={image}
                alt={imageAlt}
                objectPosition="50% 100%"
                useFocalPoint
              />
            ) : null}
          </div>
          <HeadingTag className="home-banner-symptoms__title" data-testid="home-banner__title">
            {title.map(({ value }) => (
              <DangerouslySetInnerHtml
                key={value}
                element="span"
                className="home-banner-symptoms__title-line"
                html={value}
              />
            ))}
          </HeadingTag>
          <div className="home-banner-symptoms__text-wrapper">
            {description ? (
              <DangerouslySetInnerHtml
                className="home-banner-symptoms__description"
                html={description}
              />
            ) : null}
            {link ? (
              <Button to={ctaPath} variant={variant}>
                {link.name}
                <IconCustom icon="chevron-right" />
              </Button>
            ) : null}
          </div>
        </div>
      </div>
      {veevaNumber ? (
        <DangerouslySetInnerHtml
          className="home-banner-symptoms__veeva-number"
          html={veevaNumber}
        />
      ) : null}
    </div>
  );
};

export default BannerSymptomsContent;
